var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-top-dashboard',{attrs:{"neutral":""}}),_c('v-container',{staticClass:"pt-0 px-6 mt-n16 container-general",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-n3",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"v-card-blue mb-3 px-0  v-card v-sheet theme--light elevation-0"},[_c('v-card-title',{staticClass:"pt-2 pb-0 mb-0 card-title-bloque"},[_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('p',{staticClass:"tit_card"},[_vm._v(" "+_vm._s(_vm.$t('DLT registrados'))+" ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"float-right mb-4"},[_c('v-btn',{staticClass:"text-transform-none fw400 mr-2 py-5",attrs:{"elevation":"0","color":"light-blue darken-2 white--text"},on:{"click":function($event){_vm.showDltInfo = true}}},[_vm._v(" "+_vm._s(_vm.$t('¿No registrado en DLT?'))+" ")]),_c('v-btn',{staticClass:"text-transform-none fw400 mr-3 py-5",attrs:{"color":"light-green darken-2 white--text","elevation":"0"},on:{"click":function($event){return _vm.toggleCreateDialog()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-plus-circle ")]),_vm._v(" "+_vm._s(_vm.$t("Nuevo DLT"))+" ")],1)],1)])],1)],1),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table thead-light",attrs:{"footer-props":{'items-per-page-options': [5, 10, 15, 25, 50, 100], 'disable-pagination': true, 'items-per-page-text': _vm.$t('Items por página') },"disable-pagination":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.content,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"loading-text":_vm.$t('Cargando datos... Espere por favor.'),"no-data-text":_vm.$t('No hay resultados.')},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.date(item.created_at)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getStatus(item))+" ")])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.comments)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"black !important","font-weight":"light"},attrs:{"size":"13"}},[_vm._v(" far fa-comment ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.comments))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status !== 'pendiente')?_c('v-btn-toggle',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btns_table_eliminar",attrs:{"color":"white"},on:{"click":function($event){return _vm.openDeleteDlt(item)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-trash"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Eliminar')))])])],1):_vm._e()]}}])})],1),_c('v-card-actions',{staticClass:"py-0 my-0 mt-lg-n13"},[_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#0094de","length":_vm.pageCount,"circle":""},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)],1)],1)],1)],1)],1)],1),_c('dlt-info-dialog',{attrs:{"show-dlt-info":_vm.showDltInfo},on:{"close":function($event){_vm.showDltInfo = false}}}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',{staticClass:"nocard"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('div',{staticClass:"header_modal gradient-text-color"},[_vm._v(" "+_vm._s(_vm.$t('Nuevo DLT'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){return _vm.toggleCreateDialog()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Cerrar")))])])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('id de entidad'),"rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"no-number-arrows",attrs:{"autofocus":"","type":"text","label":_vm.$t('ID de entidad') + ' ' + _vm.$t('(ej: 1701164517252001227)'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":""},model:{value:(_vm.enterpriseId),callback:function ($$v) {_vm.enterpriseId=$$v},expression:"enterpriseId"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-0 pt-0 mb-0 pb-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('encabezado'),"rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"no-number-arrows",attrs:{"type":"text","label":_vm.$t('Encabezado') + ' ' + _vm.$t('(ej: OTPKOS (1 por formulario))'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":""},model:{value:(_vm.sender),callback:function ($$v) {_vm.sender=$$v},expression:"sender"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-0 pt-0 mb-0 pb-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('id de plantilla'),"rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"no-number-arrows",attrs:{"type":"text","label":_vm.$t('ID de plantilla') + ' ' + _vm.$t('(ej: 1707164551790937269)'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":""},model:{value:(_vm.templateId),callback:function ($$v) {_vm.templateId=$$v},expression:"templateId"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-0 pt-0 mb-0 pb-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contenido de plantilla'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-textarea',{staticClass:"no-number-arrows",attrs:{"type":"text","label":_vm.$t('Contenido de plantilla'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":""},model:{value:(_vm.templateContent),callback:function ($$v) {_vm.templateContent=$$v},expression:"templateContent"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"spacer"}),_c('v-btn',{staticClass:"text-capitalize float-right",attrs:{"large":"","text":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.toggleCreateDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("Cancelar"))+" ")]),_c('v-btn',{staticClass:"pr-5",attrs:{"color":"light-green darken-2  white--text","elevation":"0","large":"","disabled":invalid,"loading":_vm.loadingCreateDialog},on:{"click":function($event){return _vm.createDlt()}}},[_c('v-icon',{staticClass:"mr-2 ml-2"},[_vm._v(" fas fa-save ")]),_vm._v(" "+_vm._s(_vm.$t("Crear"))+" ")],1)],1)],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',{staticClass:"nocard"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('div',{staticClass:"header_modal gradient-text-color"},[_vm._v(" "+_vm._s(_vm.$t('Editar DLT'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){return _vm.closeEditDlt()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Cerrar")))])])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('id de empresa'),"rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"no-number-arrows",attrs:{"type":"text","label":_vm.$t('ID de empresa'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":""},model:{value:(_vm.enterpriseId),callback:function ($$v) {_vm.enterpriseId=$$v},expression:"enterpriseId"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-0 pt-0 mb-0 pb-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('remitente'),"rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"no-number-arrows",attrs:{"type":"text","label":_vm.$t('Remitente'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":""},model:{value:(_vm.sender),callback:function ($$v) {_vm.sender=$$v},expression:"sender"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-0 pt-0 mb-0 pb-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('id de plantilla'),"rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"no-number-arrows",attrs:{"type":"text","label":_vm.$t('ID de plantilla'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":""},model:{value:(_vm.templateId),callback:function ($$v) {_vm.templateId=$$v},expression:"templateId"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-0 pt-0 mb-0 pb-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contenido de plantilla'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-textarea',{staticClass:"no-number-arrows",attrs:{"type":"text","label":_vm.$t('Contenido de plantilla'),"filled":"","error-messages":errors,"color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":""},model:{value:(_vm.templateContent),callback:function ($$v) {_vm.templateContent=$$v},expression:"templateContent"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"spacer"}),_c('v-btn',{staticClass:"text-capitalize float-right",attrs:{"large":"","text":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.closeEditDlt()}}},[_vm._v(" "+_vm._s(_vm.$t("Cancelar"))+" ")]),_c('v-btn',{staticClass:"pr-5",attrs:{"color":"light-green darken-2  white--text","elevation":"0","large":"","disabled":invalid,"loading":_vm.loadingEditDialog},on:{"click":function($event){return _vm.updateDlt()}}},[_c('v-icon',{staticClass:"mr-2 ml-2"},[_vm._v(" fas fa-save ")]),_vm._v(" "+_vm._s(_vm.$t("Guardar"))+" ")],1)],1)],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{staticClass:"nocard"},[_c('div',{staticClass:"header_modal gradient-text-color"},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"#ffffff"}},[_vm._v(" fas fa-exclamation-triangle ")]),_vm._v(" "+_vm._s(_vm.$t('Eliminar DLT'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":_vm.closeDeleteDlt}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-form',{ref:"formDirectory"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('¿Estás seguro de que quieres eliminar el DLT?'))+" ")])])],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize float-right",attrs:{"elevation":"0","large":"","text":"","color":" light-blue darken-2  white--text float-right text-capitalize"},on:{"click":function($event){return _vm.closeDeleteDlt()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")]),_c('v-btn',{staticClass:"text-capitalize float-right px-5",attrs:{"elevation":"0","large":"","color":"red darken-1  white--text","loading":_vm.loadingDeleteDialog},on:{"click":function($event){return _vm.deleteDlt()}}},[_vm._v(" "+_vm._s(_vm.$t('Eliminar'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }